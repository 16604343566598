import moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import { Badge, Button, Col, Dropdown, Row } from 'react-bootstrap';
import { ArrowUpRightCircle, Building, Person } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import OverViewColumns from '../OverViewColumns';

const minifiedColumnFields = [
  { key: 'manufacturer', label: 'Manufacturer', isObject: true, objectKey: 'factoryShortName' },
  { key: 'Serials', label: 'Serials' },
  { key: 'HUDs', label: 'HUDs' },
  { key: 'sizeA', label: 'Size A' },
  { key: 'sizeB', label: 'Size B' },
  { key: 'sizeOverall', label: 'Size Overall' },
  { key: 'dateOfManufacture', label: 'Date of Manufacture' },
  { key: 'model', label: 'Model' },
  { key: 'year', label: 'Year' },
  { key: 'floorplanLender', label: 'Floorplan Lender', isObject: true, objectKey: 'name' },
  { key: 'type', label: 'Type' },
  { key: 'sqFt', label: 'Sq. Ft' },
  { key: 'furnaceManufacturer', label: 'Furnace Manufacturer' },
  { key: 'furnaceModelNumber', label: 'Furnace Model Number' },
  { key: 'btuPerHour', label: 'B.T.U/hr' },
  { key: 'site', label: 'Site', isObject: true, objectKey: 'name' },
  { key: 'currentSlotId', label: 'Slot', isObject: true, objectKey: 'number' }
];

const overviewColumnFields = [
  ...minifiedColumnFields,
  { key: 'bedRooms', label: 'Bedrooms' },
  { key: 'bathRooms', label: 'Bathrooms' }
];

const statusToColor = {
  Stock: 'info',
  Contracted: 'success',
  RSO: 'dark'
};

const getCurtailmentCutOffDate = () => {
  const today = new Date();

  // Set year to previous year
  today.setFullYear(today.getFullYear() - 1);

  // Set to the next month
  today.setMonth(today.getMonth() + 1);

  // Set to the last day of the intended month
  today.setDate(0);

  // Set the time to just before midnight
  today.setHours(23, 59, 59, 999);

  return today.getTime();
};

const InventorySearchItem = ({
  inventory,
  onInventoryClick,
  onChangeClick,
  onRemoveClick,
  onEditClick,
  selected,
  mini = false,
  disabled,
  onAddInventoryPopupMetaChange,
  fromPicker,
  fromMap,
  showLocationDetails
}) => {
  const [inventoryItem, setInventoryItem] = useState();
  const curtailmentCutOffDate = useMemo(() => getCurtailmentCutOffDate(), []);

  useEffect(() => {
    setInventoryItem({
      ...inventory,
      site: inventory?.currentSlotId?.site,
      HUDs:
        ['hudA', 'hudB']
          .map(h => inventory[h])
          .filter(h => h)
          .join(', ') || 'N/A',
      Serials:
        ['serialA', 'serialB']
          .map(h => inventory[h])
          .filter(h => h)
          .join(', ') || 'N/A'
    });
  }, [inventory]);

  const fieldsToShow = mini ? minifiedColumnFields : overviewColumnFields;
  const filteredFieldsToShow = fieldsToShow.filter(
    field => !(showLocationDetails && (field.key === 'site' || field.key === 'slot'))
  );

  console.log('filteredFieldsToShow', inventoryItem);

  return inventoryItem ? (
    <div
      style={{ fontSize: 14 }}
      className={`d-flex ${onInventoryClick ? 'hover-light' : ''} p-1 mb-2 rounded  ${
        selected ? 'border border-primary' : ''
      }`}
      onClick={e => {
        const cellText = document.getSelection();
        if (cellText.toString().length > 0) {
          return;
        }

        if (onInventoryClick) {
          if (fromMap) {
            // Refactored: Allow the action regardless of setup date when fromMap is true
            onInventoryClick();
          } else {
            if (inventory['contract']) {
              toast.error('This unit has already been contracted!');
            } else {
              onInventoryClick();
            }
          }
        }
      }}
    >
      <div className="text-center mb-3 p-2 rounded">
        <Building size={25} />
      </div>

      <div className="flex-grow-1 pl-2">
        <div className="d-flex">
          <div className="flex-grow-1">
            {' '}
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h6 className="d-inline-block mr-2 mb-0">
                  <Link
                    className=" text-dark"
                    to={'/inventory/' + inventory['_id']}
                    target="_blank"
                    onClick={e => e.stopPropagation()}
                  >
                    {inventory['serialA'] || inventory['serialB']} <ArrowUpRightCircle size={14} />
                  </Link>
                </h6>
                {inventory['unitLocation'] && (
                  <Badge className="align-text-bottom" variant={'primary'}>
                    {inventory['unitLocation'].location}
                  </Badge>
                )}

                <Badge
                  className="align-text-bottom ml-1"
                  variant={statusToColor[inventory['inventoryStatus']] || 'primary'}
                >
                  {inventory['inventoryStatus']}
                </Badge>
                {inventory?.hasFurnitureInside?.toLowerCase() === 'yes' && (
                  <Badge className="align-text-bottom ml-2" variant="dark">
                    Has Furniture
                  </Badge>
                )}

                {inventory?.contract?.buyer && (
                  <Badge
                    className="align-text-bottom ml-1 justify-content-center align-items-center"
                    variant={'primary'}
                  >
                    <Person />
                    <Link to={'/contract/' + inventory.contract._id} style={{ color: 'white' }} target="_blank">
                      {inventory.contract.buyer}
                    </Link>
                  </Badge>
                )}

                {inventory.invoiceDate && new Date(inventory.invoiceDate).getTime() < curtailmentCutOffDate && (
                  <Badge className="align-text-bottom ml-1" variant={'warning'}>
                    Curtailment
                  </Badge>
                )}
              </div>
              {onAddInventoryPopupMetaChange && (
                <div className="align-self-center">
                  <Button
                    onClick={e => {
                      e.stopPropagation();
                      onAddInventoryPopupMetaChange({
                        ...inventory,
                        serialA: '',
                        serialB: '',
                        serialC: '',
                        hudA: '',
                        hudB: '',
                        hudC: ''
                      });
                    }}
                    size="sm"
                    variant="success"
                    className={'tinyFont'}
                  >
                    Duplicate
                  </Button>
                </div>
              )}
            </div>
            <Row className="mt-1">
              <Col xs={12} md={3}>
                <p className="mb-0  text-secondary">
                  <span className="text-dark">Mfg Date: </span>
                  {inventory['dateOfManufacture']
                    ? moment(inventory['dateOfManufacture']).format('MMMM Do YYYY')
                    : 'N/A'}
                </p>
              </Col>

              <Col xs={12} md={3} className="mt-2 mt-md-0">
                <p className="mb-0  text-secondary">
                  <span className="text-dark">Invoice: </span>${inventory['invoice'] || 'N/A'}
                </p>
              </Col>

              <Col xs={12} md={3} className="mt-2 mt-md-0">
                <p className="mb-0  text-secondary">
                  <span className="text-dark">Floorplan Finance: </span>${inventory['floorplanFinanceAmount'] || 'N/A'}
                </p>
              </Col>

              <Col xs={12} md={3} className="mt-2 mt-md-0">
                <p className="mb-0  text-secondary">
                  <span className="text-dark">Invoice Date: </span>
                  {inventory['invoiceDate'] ? moment(inventory['invoiceDate']).format('MMMM Do YYYY') : 'N/A'}
                </p>
              </Col>
            </Row>
          </div>
          {!disabled && (onChangeClick || onRemoveClick || onEditClick) && (
            <div>
              <Dropdown onClick={e => e.stopPropagation()}>
                <Dropdown.Toggle size="sm" className="p-1 tinyFont" variant="outline-primary" />
                <Dropdown.Menu>
                  {onEditClick && <Dropdown.Item onClick={onEditClick}>Edit Unit</Dropdown.Item>}
                  {onChangeClick && <Dropdown.Item onClick={onChangeClick}>Change Unit</Dropdown.Item>}
                  {onRemoveClick && <Dropdown.Item onClick={onRemoveClick}>Remove Unit</Dropdown.Item>}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>

        <hr className="my-2" />

        <OverViewColumns data={inventoryItem} fieldsToShow={filteredFieldsToShow} dateFields={['dateOfManufacture']} />
      </div>
    </div>
  ) : (
    ''
  );
};

export default InventorySearchItem;
